'use strict';

var angular = require('angular');

angular.module('app').controller('authController', require('./authController'));
angular.module('app').controller('dashboardController', require('./dashboardController'));
angular.module('app').controller('feedbackController', require('./feedbackController'));
angular.module('app').controller('instanceController', require('./instanceController'));
angular.module('app').controller('instanceListController', require('./instanceListController'));
angular.module('app').controller('navController', require('./navController'));
angular.module('app').controller('newInstanceController', require('./newInstanceController'));
angular.module('app').controller('sandboxController', require('./sandboxController'));
angular.module('app').controller('transferController', require('./transferController'));
angular.module('app').controller('userController', require('./userController'));
angular.module('app').controller('vendorBillingController', require('./vendorBillingController'));
angular.module('app').controller('customerModalController', require('./customerModalController'));
angular.module('app').controller('customerPersonModalController', require('./customerPersonModalController'));
angular.module('app').controller('newCustomerModalController', require('./newCustomerModalController'));
angular.module('app').controller('newCustomerPersonController', require('./newCustomerPersonController'));
angular.module('app').controller('orderModalController', require('./orderModalController'));
angular.module('app').controller('newOrderModalController', require('./newOrderModalController'));
angular.module('app').controller('planSelectorModalControll', require('./planSelectorModalControll'));
angular.module('app').controller('customerListController', require('./customerListController'));
angular.module('app').controller('customerController', require('./customerController'));
angular.module('app').controller('editCustomerPersonController', require('./editCustomerPersonController'));
angular.module('app').controller('instanceBandWidthModalController', require('./instanceBandWidthModalController'));
angular.module('app').controller('serverLogController', require('./serverLogController'));
angular.module('app').controller('upgradeS4Controller', require('./upgradeS4Controller'));
