'use strict';

CustomerLoader.$inject = [
  '$rootScope',
  '$http',
  'Events',
  'API'
];

function CustomerLoader($rootScope, $http, Events, API) {
  var _loadCustomerList = function(vendorId) {
    var url = API + '/api/customers/?vendorId' + vendorId;
    return $http.get(url);
  };

  return loadCustomerList(vendorId).then(function(res){
    $rootScope.$broadcast(Events.RELOAD_CUSTOMER_LIST, res.data);
  });
};

module.exports = CustomerLoader;
