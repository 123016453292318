'use strict';

Products.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Products( $rootScope, $http, API ) {
  var _loadProducts = function(vendorId, version, webServer) {
    var url = API + '/api/products/available';
    var opt = new Array();
    opt.push('vendor=' + vendorId)
    if ( version != undefined ) {
      opt.push('version=' + version);
    }
    if ( webServer != undefined ) {
      opt.push('webServer=' + webServer);
    }
    if ( opt.length ) {
      url += '?' + opt.join('&');
    }

    return $http.get(url);
  };

  return {
    listProducts: function(vendorId, version, webServer) {
      return _loadProducts(vendorId, version, webServer).then(function(res) {
        return res.data;
      });
    }
  };
};

module.exports = Products;
