'use strict';

Jobs.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Jobs( $rootScope, $http, API ) {
  var _listByVendor = function() {
    var url = API + '/api/jobs/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _rerun = function( id ) {
    var url = API + `/api/jobs/${id}/rerun`;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    listByVendor: function() {
      return _listByVendor().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    rerun: function( id ) {
      return _rerun( id ).then(
        function(res) {
          return true;
        },
        function(res) {
          return false;
        }
      )
    }
  }



};

module.exports = Jobs;
