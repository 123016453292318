'use strict';

sandboxController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$routeParams',
  '$location',
  'Instances'
];

function sandboxController($scope, $localStorage, $uibModal, $routeParams, $location, Instances) {
  $scope.displayName = $localStorage.tokenParsed.aud;
  $scope.messages = {
    status: '',
    message: ''
  };

  Instances.getInstance($routeParams.id).then(function(data){
    $scope.transferFromInstanceId = data.instanceId;
    $scope.transferFromHostName = data.instanceHostname;
    $scope.transferFromIp = data.instanceGlobalIp;
  });

  $scope.doneCommand = false;

  $scope.transferOptions = [
    {label: "既存のバックアップデータを移行する", value: 0 },
    {label: "現在のデータをバックアップして移行する（時間が掛かります）", value: 1 }
  ];
  $scope.transferData = $scope.transferOptions[0].value;

  var week = [
    {label: '今日のバックアップ', value: "TODAY"}
  ];
  var dt = new Date();
  for (var i = 0; i < 6; i++) {
    dt.setDate(dt.getDate() - 1);
    week.push({
      label: dt.getFullYear() + "年" + (dt.getMonth() + 1) + "月" + dt.getDate() + "日",
      value: dt.getFullYear() + '' + (dt.getMonth() + 1) + '' +  dt.getDate()
    });
  }
  $scope.dateOptions = week;
  $scope.backupDate = week[0].value;
  $scope.instanceOs = 'rocky';

  var _makeSandbox = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var params = {
      transferData: $scope.transferData,
      backupDate: $scope.backupDate,
      os: $scope.instanceOs + '',
    };
    if ($scope.isFTPProvide) {
      params.ftpAccount01 = $scope.ftpAccount01;
      params.ftpAccount02 = $scope.ftpAccount02;
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Instances.sandbox($scope.transferFromInstanceId, params).then(function(data){
      modal.close();

      $scope.messages.status = 'success';
      $scope.messages.message = "サンドボックスの作成を開始します。";
      $scope.doneCommand = true;
    });
  };

  var _backToInstance = function() {
    $location.path('/instances/' + $scope.transferFromInstanceId);
  };

  $scope.makeSandbox = _makeSandbox;
  $scope.back = _backToInstance;
};

module.exports = sandboxController;
