'use strict';

transferController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$routeParams',
  '$location',
  'Instances'
];

function transferController($scope, $localStorage, $uibModal, $routeParams, $location, Instances) {
  $scope.displayName = $localStorage.tokenParsed.aud;
  $scope.messages = {
    status: '',
    message: ''
  };

  Instances.getInstance($routeParams.id).then(function(data){
    $scope.transferToInstanceId = data.instanceId;
    $scope.transferToHostName = data.instanceHostname;
    $scope.transferToIp = data.instanceGlobalIp;
  });

  $scope.doneCommand = false;

  $scope.transferOptions = [
    {label: "既存のバックアップデータを移行する", value: 0 },
    {label: "現在のデータをバックアップして移行する（時間が掛かります）", value: 1 }
  ];
  $scope.transferData = $scope.transferOptions[0].value;

  var week = [
    {label: '今日のバックアップ', value: "TODAY"}
  ];
  var dt = new Date();
  for (var i = 0; i < 6; i++) {
    dt.setDate(dt.getDate() - 1);
    week.push({
      label: dt.getFullYear() + "年" + (dt.getMonth() + 1) + "月" + dt.getDate() + "日",
      value: dt.getFullYear() + '' + ('0' + (dt.getMonth() + 1)).slice(-2) + '' +  ('0' + dt.getDate()).slice(-2)
    });
  }
  $scope.dateOptions = week;
  $scope.backupDate = week[0].value;

  var _enterHostname = function() {
    if ($scope.transferFromHostName.match(/^MSS/gi)) {
      $scope.transferData = 0;
      $scope.transferOptions[1].disabled = true;
    }
    else {
      $scope.transferOptions[1].disabled = false;
    }
  };

  var _transfer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var params = {
      transferFromHostName: $scope.transferFromHostName,
      transferFromIp: $scope.transferFromIp,
      transferData: $scope.transferData,
      backupDate: $scope.backupDate
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Instances.transfer($scope.transferToInstanceId, params).then(function(data){
      modal.close();

      $scope.messages.status = 'success';
      $scope.messages.message = "環境の移行を開始します。";
      $scope.doneCommand = true;
    });
  };

  var _openInstanceList = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_instance.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope, $location, Instances){
        Instances.listInstances($scope.$parent.transferToInstanceId).then(function(data){
          $scope.instances = data.content;
          if ( data.totalElements == 0 ) {
            $scope.hideTable = true;
          }

          // Pagination
          $scope.totalItems = data.totalElements;
          $scope.currentPage = data.number + 1
          $scope.maxSize = 7;
          $scope.itemsPerPage = data.size;

          $scope.pageChanged = function() {
            $location.path('/modal/instances/pages/' + $scope.currentPage);
          };
        });

        $scope.choose = function() {
          var selected = $scope.selectedInstance;
          var items = selected.split(';');
          $scope.$parent.transferToHostName = item[0];
          $scope.$parent.transferToIp = item[1];
          $uibModalInstance.close();
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
  };

  $scope.enterHostname = _enterHostname;
  $scope.transfer = _transfer;
  $scope.openInstanceList = _openInstanceList;
};

module.exports = transferController;

