'use strict';

dashboardController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  '$q',
  '$localStorage',
  'News',
  'Jobs',
  'IaasAccounts',
  'Vendors'
];
function dashboardController($scope, $uibModal, $location, $q, $localStorage, News, Jobs, IaasAccounts, Vendors) {

  var _initialize = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadNews(),
      _loadJobs(),
      _loadInstanceCount(),
      _loadVendors()
    ])
    .then(function(){
      modal.close();
    });

    $scope.messages = {
      status: '',
      message: '',
    };
  };

  var _loadNews = function() {
    return News.listAvailableNews().then(
      function onSuccess(result){
        if (angular.isArray(result)) {
          $scope.newsList = result;
          if ( result.length > 0 ) {
            $scope.noNews = false;
          }
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function onError(result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  var _loadJobs = function(){
    return Jobs.listByVendor().then(
      function(result){
        if (angular.isArray(result.content)) {
          $scope.jobList = result.content.slice(0,10);
          if ( result.content.length > 0 ) {
            $scope.noJobs = false;
          }
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function (result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  var _loadInstanceCount = function() {
    return IaasAccounts.countInstanceByVendor().then(
      function(result){
        if (angular.isArray(result)) {
          $scope.instanceCountList = result;
          if ( result.length > 0 ) {
            $scope.noInstance = false;
          }
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function (result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  var _loadVendors = function(){
    var roles = $localStorage.tokenParsed.scopes;
    if (roles.indexOf('ROLE_ADMIN') == -1 && roles.indexOf('ROLE_SIXAPART') == -1 ) {
      return $q.resolve();
    }
    else {
      return Vendors.listVendors().then(function(data) {
        if ( !data || data.message ) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        else {
          $scope.vendors = data;
          if (!angular.isUndefined($location.search()['vendorId'])) {
              var vid = decodeURIComponent($location.search()['vendorId']);
              var selected = $scope.vendors.findIndex( v => {
              return v.vendorId == vid;
              });
              if (selected) {
                $scope.vendor = $scope.vendors[selected];
              }
          }
        }
      });
    }
  };

  var _filterInstanceList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchFreeWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "statusFilter=" + encodeURIComponent($scope.statusFilter.join(','));
    }

    if ($scope.vendor) {
      if (params.length > 0) {
        params += '&';
      }
      params += "vendorId=" + encodeURIComponent($scope.vendor.vendorId);
    }

    // Back to 1st page
    $location.path('/instances/pages/1');
    $location.search(params);
  }

  var _resetFilter = function() {
    $scope.statusFilter = [];
    $scope.searchFreeWord = '';
    $scope.vendor = null;
  }

  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    }
    else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  var _openNews = function(id) {
    News.getNews(id).then(function(data){
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/news_detail.html",
        backdrop: "static",
        scope: $scope,
        controller : function($uibModalInstance ,$scope){
          $scope.news = data;
          $scope.content = $scope.news.newsContents;
          $scope.close = function() {
            $uibModalInstance.close();
          };
        }
      });
    })
  };

  $scope.openNews = _openNews;
  $scope.filterInstanceList = _filterInstanceList;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.resetFilter = _resetFilter;

  $scope.noNews = true;
  $scope.noJobs = true;
  $scope.noInstance = true;
  $scope.statusFilter = [];
  $scope.searchFreeWord = '';

  _initialize();

};

module.exports = dashboardController;
