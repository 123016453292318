'use strict';

userController.$inject = [
  '$scope',
  '$routeParams',
  '$localStorage',
  '$uibModal',
  'Users'
];

function userController($scope, $routeParams, $localStorage, $uibModal, Users) {
  $scope.displayName = $localStorage.tokenParsed.aud;
  var userId = $routeParams.id;

  var modal = $uibModal.open({
    templateUrl: "views/modal/progress.html",
    backdrop: "static",
    keyboard: false
  });

  Users.getUser(userId).then(function(res){
    $scope.user = res.data;
    modal.close();

    $scope.updateUserInfo = function() {
      $scope.messages = {
        status: '',
        message: ''
      };

      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });
      Users.updateUser($scope.user).then(function(res){
        $scope.user = res.data;
        modal.close();
        $scope.messages.status = 'success';
        $scope.messages.message = "ユーザー情報を更新しました。";
      })
    };
  })
};

module.exports = userController;
