'use strict';

instanceListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$localStorage',
  '$q',
  'Instances',
  'Vendors'
];

function instanceListController($scope, $uibModal, $routeParams, $location, $localStorage, $q, Instances, Vendors) {

  var _loadInstances = function() {
    return Instances.listInstances($routeParams.num, $location.search()).then(function(data){
      $scope.instances = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/instances/pages/' + $scope.currentPage);
      };
    })
  };

  var _loadVendors = function() {
    var roles = $localStorage.tokenParsed.scopes;
    if (roles.indexOf('ROLE_ADMIN') == -1 && roles.indexOf('ROLE_SIXAPART') == -1 ) {
      return $q.resolve();
    }
    else {
      return Vendors.listVendors().then(function(data) {
        if ( !data || data.message ) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        else {
          $scope.vendors = data;
          if (!angular.isUndefined($location.search()['vendorId'])) {
            var vid = decodeURIComponent($location.search()['vendorId']);
            var selected = $scope.vendors.findIndex( v => {
              return v.vendorId == vid;
            });
            if (selected) {
              $scope.vendor = $scope.vendors[selected];
            }
          }
        }
      });
    }
  };

  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadInstances(),
      _loadVendors()
    ])
    .then(function(){
      modal.close();
    });
  };

  var _filterInstanceList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchFreeWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "statusFilter=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ($scope.vendor) {
      if (params.length > 0) {
        params += '&';
      }
      params += "vendorId=" + encodeURIComponent($scope.vendor.vendorId);
    }

    // Back to 1st page
    $location.path('/instances/pages/1');
    $location.search(params);

    _load();
  }

  var _resetFilter = function() {
    $scope.statusFilter = [];
    $scope.searchFreeWord = '';
    $scope.vendor = null;
    $location.url('/instances/pages/1');
  }

  $scope.statusFilter = [];
  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    }
    else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchFreeWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchFreeWord'])
  }
  if (!angular.isUndefined($location.search()['statusFilter'])) {
    $scope.statusFilter = decodeURIComponent($location.search()['statusFilter']).split(',');
  }

  // Load initial list
  _load();

  $scope.filterInstanceList = _filterInstanceList;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.resetFilter = _resetFilter;
};

module.exports = instanceListController;
