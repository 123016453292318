'use strict';

newOrderModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  '$localStorage',
  'Products',
  'Orders'
];

function newOrderModalController($uibModalInstance, $scope, $uibModal, $localStorage, Products, Orders) {

  $scope.versions = [
    {id: 8, label: "Movable Type 8"},
    {id: 7, label: "Movable Type 7"},
    {id: 6, label: "Movable Type 6"},
  ];

  $scope.webServers = [
    {id: "nginx", label: "nginx"},
    {id: "httpd", label: "Apache"},
  ];

  $scope.paymentTerms = [
    { id: "1", label: "月額" },
    { id: "3", label: "年額" }
  ]

  $scope.paymentMethods = [
    { id: "3", label: "請求書"},
    { id: "4", label: "MF KESSAI"},
    { id: "5", label: "STRIPE"},
    { id: "8", label: "無償（検証含む）"},
  ];

  $scope.orderDate = new Date();
  $scope.customer = null
  $scope.customerPerson = null;

  Products.listProducts($scope.$parent.vendorId).then(function(data){
    $scope.products = data;
  });

  $scope.changeCriteria = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Products.listProducts($scope.$parent.vendorId, $scope.productVersion, $scope.webServer).then(function(data){
      $scope.products = data;
      modal.close();
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Open Customer Search Modal Window
  var _searchCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerModalController'
    });

    // Result
    modal.result.then(
      function(data){
        if ( $scope.customer && $scope.customer.customerId != data.customerId) {
          $scope.customerPerson = null;
        }
        $scope.customer = data;
        $scope.order = null;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Person Search Modal Window
  var _searchCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    if ($scope.customer == null)
      return;

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerPersonModalController'
    });

    // Result
    modal.result.then(
      function(data){
        $scope.customerPerson = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open new customer modal
  var _createNewCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var customerModal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'newCustomerModalController'
    });

    customerModal.result.then(
      function (data) {
        $scope.customer = data;
        $scope.customerPerson = null;
        $scope.order = null;
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open new customer modal
  var _createNewCustomerPersoon = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller : 'newCustomerPersonController'
    });

    modal.result.then(
      function (data) {
        $scope.customerPerson = data;
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  $scope.save = function() {
    var params = {
      customerId: $scope.customer.customerId,
      customerPersonId: $scope.customerPerson.customerPersonId,
      vendorId: $scope.$parent.vendorId,
      productId: $scope.productId,
      paymentTerm: $scope.paymentTerm,
      orderStatus: '1'
    };
    if ( $scope.isDirect ) {
      params.paymentMethod = $scope.paymentMethod
    }
    if ( $scope.orderDate ) {
      var yyyy = $scope.orderDate.getFullYear();
      var mm = ('0' + ($scope.orderDate.getMonth() + 1)).slice(-2);
      var dd = ('0' + ($scope.orderDate.getDate())).slice(-2)
      params.date = yyyy + '-' + mm + '-' + dd;
    }

    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Orders.save(params).then(function(data){
      modal.close();
      $uibModalInstance.close(data);
    })
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.openNewCusotmer = _createNewCustomer;
  $scope.openNewCusotmerPerson = _createNewCustomerPersoon;
  $scope.searchCustomer = _searchCustomer;
  $scope.searchCustomerPerson = _searchCustomerPerson;

  var roles = $localStorage.tokenParsed.scopes;
  if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 ) {
    $scope.isDirect = true;
  }

}

module.exports = newOrderModalController;
