'use strict';

customerModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers'
];

function customerModalController($uibModalInstance, $scope, $uibModal, Customers) {

  // Initialize
  $scope.selectedRow = null;

  // Load Customer
  var _listCustomersByVendor = function(page) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    var params = {
      vendorId: $scope.$parent.vendorId,
      search: $scope.searchFreeWord
    }
    Customers.listCustomersByVendor(page, params).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.customers = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  _listCustomersByVendor(1);

  // Search
  $scope.filterCustomerList = function() {
    $scope.selectedRow = null;
    $scope.customerName = null;
    $scope.customer = null;
    $scope.hideTable = false;
    _listCustomersByVendor(1);
  };

  // Page move
  $scope.pageChanged = function() {
    $scope.selectedRow = null;
    $scope.customerName = null;
    $scope.customer = null;
    $scope.hideTable = false;
    _listCustomersByVendor($scope.currentPage);
  };

  // Select Row
  $scope.selectRow = function(index) {
    if ($scope.selectedRow == index) {
      $scope.customerName = null;
      $scope.customer = null;
      $scope.selectedRow = null;
    } else {
      $scope.customer = $scope.customers[index];
      $scope.customerName = $scope.customer.customerName;
      $scope.selectedRow = index;
    }
  };

  // OK
  $scope.choose = function() {
    $uibModalInstance.close($scope.customer);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

}

module.exports = customerModalController;
