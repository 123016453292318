'use strict';

instanceController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$routeParams',
  '$location',
  '$q',
  'Instances',
  'Jobs'
]

function instanceController($scope, $localStorage, $uibModal, $routeParams, $location, $q, Instances, Jobs) {
  var _stopInstance = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    Instances.stopInstance($scope.instance.instanceId).then(function(){
      $scope.messages.status = 'success';
      $scope.messages.message = 'インスタンスを停止します。';
      modal.close();
    });
  };

  var _startInstance = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    Instances.startInstance($scope.instance.instanceId).then(function(){
      $scope.messages.status = 'success';
      $scope.messages.message = 'インスタンスを開始します。';
      modal.close();
    });
  };

  var _rebootInstance = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    Instances.rebootInstance($scope.instance.instanceId).then(function(){
      $scope.messages.status = 'success';
      $scope.messages.message = 'インスタンスを再起動します。';
      modal.close();
    });
  };

  var _destroyInstance = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/destroy_instance.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "インスタンスの削除";
        $scope.messageBody = "インスタンス [" + $scope.$parent.instance.instanceHostname + "] を削除します。よろしいですか？";
        $scope.notifyAtDelete = true;
        $scope.okay = function() {
          $scope.$parent.notifyAtDelete = $scope.notifyAtDelete;
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });
      Instances.destroy($routeParams.id, false, $scope.notifyAtDelete).then(function(data){
        $scope.messages.status = 'success';
        $scope.messages.message = "インスタンスを削除対象として停止しました。削除は7日後に実行されます。";
        modal.close();
      })
    }, function () {
    });
  };

  var _destroyInstanceImmediately = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/destroy_instance.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "インスタンスの即時削除";
        $scope.messageBody = "インスタンス [" + $scope.$parent.instance.instanceHostname + "] を即時削除します。この操作はキャンセルできません。よろしいですか？";
        $scope.notifyAtDelete = true;
        $scope.okay = function() {
          $scope.$parent.notifyAtDelete = $scope.notifyAtDelete;
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Instances.destroy($routeParams.id, true, $scope.notifyAtDelete).then(function(data){
        $scope.messages.status = 'success';
        $scope.messages.message = "インスタンスを削除対象として停止しました。削除は即時実行されます。";
        modal.close();
      })
    }, function () {
    });
  };

  var _transrer = function() {
    $location.path('/instances/' + $scope.instance.instanceId + '/transfer')
  };

  var _sandbox = function() {
    $location.path('/instances/' + $scope.instance.instanceId + '/sandbox')
  };

  var _changePlanWithDeploy = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_plan.html",
      backdrop: "static",
      scope: $scope,
      controller : 'planSelectorModalControll',
      resolve: {
        params: function() {
          return {
            instance: $scope.instance,
            flex: true
          }
        }
      }
    });

    modal.result.then(
      function (data) {
        var params = {
          customerId: $scope.instance.order.customer.customerId + '',
          orderId: $scope.instance.order.orderId + '',
          prefix: $scope.instance.instanceDomainName.split("-")[0],
          usage: $scope.instance.instanceUsage + '',
          vendorId: $scope.instance.order.vendor.vendorId + '',
          nickname: $scope.instance.instanceInitial.instanceInitialMtAdminNickname,
          email: $scope.instance.instanceInitial.instanceInitialMtAdminEmail,
          isProvision: 'true',
          cloudTypeId: data.cloudType.cloudTypeId + '',
          newProductId: data.productId,
          iaasAccountId: $scope.instance.iaasAccount.iaasAccountId,
          ftpAccount01: $scope.instance.instanceFtpAdminAccount,
          ftpAccount02: $scope.instance.instanceFtpUserAccount,
          os: 'rocky',
        };

        $scope.messages.status = '';
        $scope.messages.message = '';
        var modal = $uibModal.open({
          templateUrl: "views/modal/progress.html",
          backdrop: "static",
          keyboard: false
        });

        Instances.deploy(params).then(function(data){
          modal.close();

          if ( data.error || data.message ) {
            $scope.messages.status = 'danger';
            if ( data.message == "null" )
              $scope.messages.message = '不明なエラーが発生しました。';
            else
              $scope.messages.message = data.message;
          }
          else {
            $scope.messages.status = 'success';
            $scope.messages.message = "プラン変更先のインスタンスの作成を開始しました。";
          }
        });
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  var _changePlan = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_plan.html",
      backdrop: "static",
      scope: $scope,
      controller : 'planSelectorModalControll',
      resolve: {
        params: function() {
          return {
            instance: $scope.instance,
            flex: false
          }
        }
      }
    });

    modal.result.then(
      function (data) {
        var params = {
          productId: data.productId,
        };

        $scope.messages.status = '';
        $scope.messages.message = '';
        var modal = $uibModal.open({
          templateUrl: "views/modal/progress.html",
          backdrop: "static",
          keyboard: false
        });

        Instances.changePlan($scope.instance.instanceId, params).then(
          function(result){
            modal.close();
            if (result.message == undefined) {
              // registered job
              $scope.messages.status = 'success';
              $scope.messages.message = "プランの変更を開始します。";
            } else {
              $scope.messages.status = 'danger';
              $scope.messages.message = result.message;
            }
          },
          function(datresulta) {
            modal.close();
            $scope.messages.status = 'danger';
            $scope.messages.message = result;
          }
        );
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  var _upgradeS4 = function() {
    $location.path('/instances/' + $scope.instance.instanceId + '/upgradeS4')
  };

  var _exchange = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_host.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope, Customers, Products){
        $scope.go = function() {
          var params = {
            vmname: $scope.vmname,
          };

          $scope.messages.status = '';
          $scope.messages.message = '';
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          Instances.exchange($scope.instance.instanceId, params).then(function(data){
            modal.close();
            if (data.message == undefined) {
              $uibModalInstance.close();
            } else {
              $uibModalInstance.dismiss(data.message);
            }
          });
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(
      function () {
        // registered job
        $scope.messages.status = 'success';
        $scope.messages.message = "IPアドレスを交換します。";
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  var _changeExpireDays = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_expire_days.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope, Customers, Products){
        $scope.options = [
          {key : "リリース後 30日（デフォルト）", value : 30 },
          {key : "リリース後 60日", value : 60 },
          {key : "リリース後 90日", value : 90 },
        ];
        if ( $scope.instance.instanceExpireDays ) {
          if ( $scope.instance.instanceExpireDays == 30 ) {
            $scope.days = $scope.options[0].value;
          }
          else if ( $scope.instance.instanceExpireDays == 60 ) {
            $scope.days = $scope.options[1].value;
          }
          else if ( $scope.instance.instanceExpireDays == 90 ) {
            $scope.days = $scope.options[2].value;
          }
        }
        else {
          $scope.days = $scope.options[0].value;
        }

        $scope.go = function() {
          var params = {
            days: $scope.days,
          };

          $scope.messages.status = '';
          $scope.messages.message = '';
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          Instances.changeExpireDays($scope.instance.instanceId, params).then(function(data){
            modal.close();
            if (data.message == undefined) {
              $uibModalInstance.close();
            } else {
              $uibModalInstance.dismiss(data.message);
            }
          });
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(
      function () {
        // registered job
        $scope.messages.status = 'success';
        $scope.messages.message = "アップデート猶予期間を設定します。";
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  var _cancelDestroy = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "インスタンスの削除を取り消す";
        $scope.messageBody = "インスタンス [" + $scope.$parent.instance.instanceHostname + "] の削除を取り消します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Instances.cancelDestroy($routeParams.id).then(
        function(result){
          if (result.message == undefined) {
            $scope.messages.status = 'success';
            $scope.messages.message = "インスタンスの削除を取り消しました。インスタンスは停止状態です。";
            modal.close();
          } else {
            $scope.messages.status = 'danger';
            $scope.messages.message = result.message;
            modal.close();
          }
        },
        function (result) {
          if (result != 'cancel') {
            $scope.messages.status = 'danger';
            $scope.messages.message = result;
            modal.close();
          }
        }
      )
    }, function () { });
  };

  var _domainExchange = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_host.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope){
        $scope.go = function() {
          var params = {
            hostname: $scope.vmname,
          };

          $scope.messages.status = '';
          $scope.messages.message = '';
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          Instances.domainExchange($scope.instance.instanceId, params).then(function(data){
            modal.close();
            if (data.message == undefined) {
              $uibModalInstance.close();
            } else {
              $uibModalInstance.dismiss(data.message);
            }
          });
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(
      function () {
        // registered job
        $scope.messages.status = 'success';
        $scope.messages.message = "ドメインを交換します。";
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open network bandwidth list
  var _openNetworkData = function(type) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/instance_band_width.html",
      backdrop: "static",
      scope: $scope,
      controller: 'instanceBandWidthModalController',
      resolve: {
        params: function() {
          return {
            instanceId: $scope.instance.instanceId,
            instanceHostname: $scope.instance.instanceHostname
          }
        }
      }
    });
  };

  var _migration = function ( cmd ) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "移行モードの変更";
        var mode = cmd == "enable" ? "有効" : "無効";
        $scope.messageBody = `インスタンス [${$scope.$parent.instance.instanceHostname}] の移行モードを${mode}にします。よろしいですか？`;
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      return Instances.migration( $scope.instance.instanceId, cmd ).then( function(result) {
        if ( result.status == "enable" ) {
          $scope.action.canMigrationDisable = true;
          $scope.action.canMigrationEnable = false;
        }
        if ( result.status == "disable" ) {
          $scope.action.canMigrationDisable = false;
          $scope.action.canMigrationEnable = true;
        }
      }, function () {} );
    });
  };

  var _enableMigration = function() { _migration( "enable" )};
  var _disableMigration = function() { _migration( "disable" )};

  var _upgradeMT8 = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "Movable Type 8 へのアップグレード";
        $scope.messageBody = `インスタンス [${$scope.$parent.instance.instanceHostname}] を Movable Type 8 にアップグレードします。よろしいですか？`;
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      return Instances.upgradeMT8( $scope.instance.instanceId).then( function(result) {
      }, function () {} );
    });
  };

  var _rollback = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/rollback.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.choose = function() {
          return Instances.rollback( $scope.$parent.instance.instanceId, $scope.backupDate )
            .then( function() {
              $scope.$parent.canRollback = false;
              $uibModalInstance.close();
            });
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      },
    });
  };

  var _installClamAV = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "ウイルススキャン(ClamAV)のインストール";
        $scope.messageBody = "インスタンス [" + $scope.$parent.instance.instanceHostname + "] に ClamAV をインストールします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        }
      }
    });
    modal.result.then(function () {
      return Instances.installClamAV( $scope.instance.instanceId).then( function(result) {
      }, function () {} );
    });
  };

  var _rerunJob = function(id) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "Jobの再実行";
        $scope.messageBody = "Job: " + id + "を再実行します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        }
      }
    });
    modal.result.then(function () {
      return Jobs.rerun(id).then( function(res) {}, function () {} );
    });
  };

  var _installShadankun = function( id ) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/install_shadankun.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope) {
        $scope.install = function() {
          var params = {
            agentkey: $scope.shadankunAgentKey,
            managehost: $scope.shadankunManageHost,
            manageport: $scope.shadankunManagePort
          };
          return Instances.installShadankun( $scope.$parent.instance.instanceId, params )
            .then( function() {
              $uibModalInstance.close();
            });
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
  }

  var _uninstallShadankun = function( id ) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        const hostname = $scope.instance.instanceHostname;
        $scope.title = "攻撃遮断くんのアンインストール";
        $scope.messageBody = `インスタンス [${hostname}] の攻撃遮断くんをアンインストールします。よろしいですか？`;
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        }
      }
    });
    modal.result.then(function () {
      return Instances.uninstallShadankun( $scope.instance.instanceId).then( function(result) {
      }, function () {} );
    });
  }

  var _controlShadankun = function( mode ) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        const labels = {
          start: "開始",
          stop: "停止",
          restart: "再起動",
          reload: "リロード",
        };
        const label = labels[mode];
        const hostname = $scope.instance.instanceHostname;
        $scope.title = `攻撃遮断くんの${label}`,
        $scope.messageBody = `インスタンス [${hostname}] の攻撃遮断くんを ${label} します。よろしいですか？`;
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        }
      }
    });
    modal.result.then(function () {
      return Instances.controlShadankun( $scope.instance.instanceId, mode ).then( function(result) {
      }, function () {} );
    });
  }

  var _startShadankun = function() { _controlShadankun( "start" ) };
  var _stopShadankun = function() { _controlShadankun( "stop" ) };
  var _restartShadankun = function() { _controlShadankun( "restart" ) };
  var _reloadShadankun = function() { _controlShadankun( "reload" ) };

  var _instanceDetailModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/instance_detail.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        _loadAuthors();
        _loadSites();
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }
    });
  }
  $scope.openInstanceDetailModal = _instanceDetailModal;

  $scope.stopInstance = _stopInstance;
  $scope.startInstance = _startInstance;
  $scope.rebootInstance = _rebootInstance;
  $scope.transrer = _transrer;
  $scope.destroy = _destroyInstance;
  $scope.destroyImmediately = _destroyInstanceImmediately;
  $scope.sandbox = _sandbox;
  $scope.changePlan = _changePlan;
  $scope.exchange = _exchange;
  $scope.changeExpireDays = _changeExpireDays;
  $scope.cancelDestroy = _cancelDestroy;
  $scope.domainExchange = _domainExchange;
  $scope.changePlanWithDeploy = _changePlanWithDeploy;
  $scope.openNetworkData = _openNetworkData;
  $scope.upgradeS4 = _upgradeS4;
  $scope.enableMigration = _enableMigration;
  $scope.disableMigration = _disableMigration;
  $scope.upgradeMT8 = _upgradeMT8;
  $scope.rollback = _rollback;
  $scope.installClamAV = _installClamAV;
  $scope.rerun = _rerunJob;
  $scope.installShadankun = _installShadankun;
  $scope.uninstallShadankun = _uninstallShadankun;
  $scope.startShadankun = _startShadankun;
  $scope.stopShadankun = _stopShadankun;
  $scope.restartShadankun = _restartShadankun;
  $scope.reloadShadankun = _reloadShadankun;

  var _loadInstance = function() {
    return Instances.getInstance($routeParams.id).then(function(data){
      $scope.instance = data;
      $scope.editMode = false;

      // Action State
      $scope.action = {};
      $scope.action.canDoAnything = false;
      var roles = $localStorage.tokenParsed.scopes;

      if ( data.instanceStatus == 1) {
        $scope.action.viewServerLog = true;
        $scope.action.divider4 = true;
      }

      var zone1 = false;
      $scope.action.canStartInstance = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus != -1 && data.instanceStatus != 2 && data.instanceStatus != 3 && data.instanceStatus != 5 && data.instanceStatus != 8 && data.instanceStatus != 10) {
          $scope.action.canStartInstance = true;
          $scope.action.canDoAnything = true;
          zone1 = true;
        }
      }

      $scope.action.canStopInstance = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 4 ||
            data.instanceStatus == 6) {
          $scope.action.canStopInstance = true;
          $scope.action.canDoAnything = true;
          zone1 = true;
        }
      }

      $scope.action.canRestartInstance = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1) {
          $scope.action.canRestartInstance = true;
          $scope.action.canDoAnything = true;
          zone1 = true;
        }
      }

      var zone2 = false;
      $scope.action.divider1 = false;
      $scope.action.canTransfer = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 7) {
          $scope.action.canTransfer = true;
          $scope.action.canDoAnything = true;
          zone2 = true;
          if (zone1) {
            $scope.action.divider1 = true;
          }
        }
      }

      $scope.action.canUpgradeS4 = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 4 ||
            data.instanceStatus == 10) {
          $scope.action.canUpgradeS4 = true;
          $scope.action.canDoAnything = true;
          zone2 = true;
          if (zone1) {
            $scope.action.divider1 = true;
          }
        }
      }

      $scope.action.canMakeSandbox = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1) {
          $scope.action.canMakeSandbox = true;
          $scope.action.canDoAnything = true;
          zone2 = true;
          if (zone1) {
            $scope.action.divider1 = true;
          }
        }
      }

      var zone3 = false;
      $scope.action.divider2 = false;
      $scope.action.canChangePlan = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 4 ||
            data.instanceStatus == 10) {
          $scope.action.canChangePlan = true;
          $scope.action.canDoAnything = true;
          zone3 = true;
          if (zone2) {
            $scope.action.divider2 = true;
          }
        }
      }

      $scope.action.canChangePlanWithDeploy = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 4 ||
            data.instanceStatus == 10) {
          $scope.action.canChangePlanWithDeploy = true;
          $scope.action.canDoAnything = true;
          zone3 = true;
          if (zone2) {
            $scope.action.divider2 = true;
          }
        }
      }

      $scope.action.canExchange = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1) {
        if (data.instanceStatus == 1) {
          $scope.action.canExchange = true;
          $scope.action.canDoAnything = true;
          zone3 = true;
          if (zone2) {
            $scope.action.divider2 = true;
          }
        }
      }

      $scope.action.canDomainExchange = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1) {
          $scope.action.canDomainExchange = true;
          $scope.action.canDoAnything = true;
          zone3 = true;
          if (zone2) {
            $scope.action.divider2 = true;
          }
        }
      }

      $scope.action.canChangeExpireDays = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1) {
        if (data.instanceStatus == 1) {
          $scope.action.canChangeExpireDays = true;
          $scope.action.canDoAnything = true;
          zone3 = true;
          if (zone2) {
            $scope.action.divider2 = true;
          }
        }
      }

      $scope.action.divider3 = false;
      $scope.action.canDestroy = false;
      $scope.action.canDestroyImmediately = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 1 ||
            data.instanceStatus == 3 ||
            data.instanceStatus == 8) {
          $scope.action.canDestroy = true;
          $scope.action.canDestroyImmediately = true;
          $scope.action.canDoAnything = true;
          if (zone3) {
            $scope.action.divider3 = true;
          }
        }
      }

      $scope.action.canCancelDestroy = false;
      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if (data.instanceStatus == 5) {
          $scope.action.canCancelDestroy = true;
          $scope.action.canDoAnything = true;
          if (zone3) {
            $scope.action.divider3 = true;
          }
        }
      }

      var _instanceAvailableInstallClamAV = function( detail ) {
        var plan = detail.instanceDetailPlan;
        var version = detail.instanceDetailProductVersion;
        if ( version < 8 ) {
          return false;
        }
        if ( plan.match( "^(M|L|XL)(8|16)" ) ) {
          return true;
        }
        return false;
      };

      $scope.action.canInstallClamAV = false;
      if ( roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 ) {
        if (_instanceAvailableInstallClamAV( data.instanceDetail )) {
          $scope.action.canInstallClamAV = true;
          zone3 = true;
          $scope.action.divider3 = true;
        }
      }

      $scope.action.canInstallShadankun = false;
      if ( roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 ) {
        if ( data.instanceDetail.instanceDetailShadankun != 1 ) {
          $scope.action.canInstallShadankun = true;
          zone3 = true;
          $scope.action.divider3 = true;
        }
      }

      $scope.action.canUninstallShadankun = false;
      if ( roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 ) {
        if ( data.instanceDetail.instanceDetailShadankun == 1 ) {
          $scope.action.canUninstallShadankun = true;
          zone3 = true;
          $scope.action.divider3 = true;
        }
      }

      $scope.action.canControlShadankun = false;
      if ( roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 ) {
        if ( data.instanceDetail.instanceDetailShadankun == 1 ) {
          $scope.action.canControlShadankun = true;
          zone3 = true;
          $scope.action.divider3 = true;
          Instances.getShadankunStatus( $routeParams.id ).then(
            function(r) {
              $scope.status = $scope.status || {};
              $scope.status.shadankun = r.status;
            });
        }
      }

      if (roles.indexOf('ROLE_ADMIN') != -1 || roles.indexOf('ROLE_SIXAPART') != -1 || roles.indexOf('ROLE_VENDOR') != -1) {
        if ( data.instanceStatus == 1 ) {

          $scope.action.canUpgradeMT8 = false;
          $scope.action.canRollback = false;
          $scope.instance.isRestoreRunning = false;
          if ( data.instanceOs == 'rocky' && data.instanceDetail.instanceDetailProductVersion == 7 ) {
            $scope.action.canUpgradeMT8 = true;
          }
          if ( data.instanceOs == 'rocky' && data.instanceDetail.instanceDetailProductVersion == 8 ) {
            return Instances.restoreStatus( $routeParams.id ).then( function (r) {
              if ( r.status == 'notrunning' ) {
                $scope.action.canRollback = true;
              }
              if ( r.status == 'running' ) {
                $scope.instance.isRestoreRunning = true;
              }
            })
            .then( function (r) {
              if ($scope.action.canRollback) {
                return Instances.getBackupFiles( $routeParams.id ).then( function (r) {
                  const now = new Date();
                  const today = `${now.getFullYear()}` + `0${now.getMonth()+1}`.slice(-2) + `0${now.getDate()}`.slice(-2);
                  let backups = [];
                  const data = r.sort( (a,b) => ( b.date - a.date ) );
                  for ( const b of data ) {
                    if ( b.version.match( /^7/ ) ) {
                      if ( b.date == today ) {
                        backups.push( { label: b.date, value: 'TODAY' } );
                      } else {
                        backups.push( { label: b.date, value: b.date });
                      }
                    }
                  }
                  $scope.backupFiles = backups;
                  if ( backups.length == 0 ) {
                    $scope.action.canRollback = false;
                  }
                })
              }
            });
          }
        }
      }
    });
  }

  var _loadMigrationStatus = function() {
    return Instances.getMigrationStatus( $routeParams.id ).then( function (data) {
      $scope.action.canMigrationEnable = data.status == "disable" ? true : false;
      $scope.action.canMigrationDisable = data.status == "enable" ? true : false;
    });
  }

  var _loadLogs = function() {
    return Instances.getJobs($routeParams.id).then(function(data){
      $scope.jobs = data;
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Loading instance disk usage data
  var _loadDiskUsage = function() {

    return Instances.getInstanceDiskUsage($routeParams.id).then(function(data){
      $scope.diskMetrics = data;
    });

  };

  //////////////////////////////////////////////////////////////////////
  // Loading network bandwidth
  var _loadBandwidth = function() {
    return Instances.getNetworkBandWidth($routeParams.id, null).then(function(data){
      if ( ! 'instance' in $scope ) {
        return;
      }

      const GiB = 1024 * 1024 * 1024;
      const TiB = GiB * 1024;

      const _is_private = function ( instance ) {
        const seller = (typeof($scope.instance) == 'object' && 'order' in $scope.instance) ? $scope.instance.order.seller : {} ;
        if ( seller == null ) {
          return false;
        }
        return seller.sellerName == 'プライベート' ? true : false;
      }

      const plan = ( typeof($scope.instance) == 'object' && 'instanceDetail' in $scope.instance ) ? $scope.instance.instanceDetail.instanceDetailPlan : undefined ;
      const freetier = plan ? _freeBandwidthByPlan( plan ) : 0 ;
      $scope.freetier = ( freetier == 0 || _is_private( $scope.instance ) ) ? '' : `転送量枠: ${ Number(( freetier / TiB ).toFixed(1)).toLocaleString('ja') } TiB / ${ Number(( freetier / GiB ).toFixed(0)).toLocaleString('ja') } GiB`;

      if ( data && data.bandwidth && data.bandwidth.length > 0 ) {
        const bw = data.bandwidth[0]
        const raw = bw.raw;
        bw.bandwidth = `${ Number( (raw / GiB).toFixed(2) ).toLocaleString('ja') } GiB`;

        const excess = ( raw > freetier ) ? raw - freetier : 0;
        bw.excess = (excess > 0) ? `今月の転送量枠を ${ Math.ceil( excess / GiB ).toLocaleString('ja')} GiB 超過しています` : "";

        const d = new Date();
        const passed = d.getDate() > 1 ? d.getDate() - 1 : 1;
        const days_this_month = ( new Date(d.getFullYear(), d.getMonth()+1, 0) ).getDate();
        const forecast = raw * days_this_month / passed;

        bw.forecast = (forecast > 0) ? `当月転送量の予想: ${Number( (forecast / GiB).toPrecision(3) ).toLocaleString('ja')} GiB` : "";
        $scope.bandWidth = bw;

      } else {
        $scope.bandWidth = {
          bandwidth : "0 GiB",
          excess : "",
          forecast : "",
        }
      }
    })
  };

  const _freeBandwidthByPlan = function ( plan ) {
    const GiB = 1024 * 1024 * 1024;
    const TiB = GiB * 1024;
    let bandwidth;
    if ( plan.match( /^(S[12]|M3)/ ) ) {
      bandwidth = 1 * TiB;
    } else if ( plan.match( /^(S4|M4)/ ) ) {
      bandwidth = 2 * TiB;
    } else if ( plan.match( /^(M16|L8|L16|XL16)/ )) {
      bandwidth = 3 * TiB;
    }
    return bandwidth;
  }

  //////////////////////////////////////////////////////////////////////
  // Loading instance cpu usage data
  var _loadCpuUsage = function() {
    return Instances.getInstanceCpuUsage($routeParams.id).then(function(data){
      if ( data && data.labels ) {
        $scope.cpuMetricsLabels  = data.labels;
        $scope.cpuMetricsData  = data.data;
        $scope.cpuMetricsDatasetOverride = [{ yAxisID: 'y-axis-1' }];
        $scope.cpuMetricsOptions = {
          colors : [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
          title: {
            display: true,
            text: 'CPU 推移（％）',
            position: 'bottom'
          },
          scales: {
            yAxes: [
              {
              id: 'y-axis-1',
              ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100
                }
              }
            ]
          }
        };
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading instance memory usage data
  var _loadMemoryUsage = function() {
    return Instances.getInstanceMemoryUsage($routeParams.id).then(function(data){
      if ( data && data.labels ) {
        $scope.memoryMetricsLabels  = data.labels;
        $scope.memoryMetricsData  = data.data;
        $scope.memoryMetricsDatasetOverride = [{ yAxisID: 'y-axis-1' }];
        $scope.memoryMetricsOptions = {
          colors : [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
          title: {
            display: true,
            text: 'メモリ 推移（メガバイト）',
            position: 'bottom'
          },
          scales: {
            yAxes: [
              {
              id: 'y-axis-1',
              ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: data.size
                }
              }
            ]
          }
        };
      }
    });
  };

  var _loadAuthors = function() {
    return Instances.listAuthors($routeParams.id).then(function(data){
      $scope.authors = data;
    });
  }

  var _loadSites = function() {
    return Instances.listSites($routeParams.id).then(function(data){
      $scope.sites = data;
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Loading data
  var _load = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadInstance(),
      _loadDiskUsage(),
      _loadCpuUsage(),
      _loadMemoryUsage(),
      _loadBandwidth(),
      _loadMigrationStatus(),
      _loadLogs()
    ])
    .then(function(){
      modal.close();
    })
    .catch( function(){ modal.close(); });

  };

  $scope.messages = {
    status: '',
    message: ''
  };
  _load();

};

module.exports = instanceController;
