'use strict';

CustomerPersons.$inject = [
  '$http',
  'API'
];

function CustomerPersons ( $http, API ) {
  var _listCustomerPersonList = function(customerId) {
    var url = API + '/api/customerPersons/available?customerId=' + customerId;
    return $http.get(url);
  };

  var _save = function(params) {
    var url = API + '/api/customerPersons/';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _update = function(customerPersonId, params) {
    var url = API + '/api/customerPersons/' + customerPersonId;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _delete = function(customerPersonId) {
    var url = API + '/api/customerPersons/' + customerPersonId;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _get = function(customerPersonId) {
    var url = API + '/api/customerPersons/' + customerPersonId;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    listCustomerPersonList: function(customerId ) {
      return _listCustomerPersonList(customerId).then(function(res) {
        return res.data;
      });
    },
    save: function(params) {
      return _save(params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    update: function(customerPersonId, params) {
      return _update(customerPersonId, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    delete: function(customerPersonId) {
      return _delete(customerPersonId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    get: function(customerPersonId) {
      return _get(customerPersonId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = CustomerPersons;
