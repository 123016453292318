'use strict';

upgradeS4Controller.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$routeParams',
  '$location',
  'Instances'
];

function upgradeS4Controller($scope, $localStorage, $uibModal, $routeParams, $location, Instances) {
  $scope.displayName = $localStorage.tokenParsed.aud;
  $scope.messages = {
    status: '',
    message: ''
  };

  Instances.getInstance($routeParams.id).then(function(data){
    $scope.upgradeS4ToInstanceId = data.instanceId;
    $scope.upgradeS4ToHostName = data.instanceHostname;
    $scope.upgradeS4ToIp = data.instanceGlobalIp;
  });

  $scope.doneCommand = false;

  var _upgradeS4 = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var params = {
      upgradeS4FromHostName: $scope.upgradeS4FromHostName,
      upgradeS4FromIp: $scope.upgradeS4FromIp,
      upgradeS4Domain: $scope.upgradeS4Domain,
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Instances.upgradeS4($scope.upgradeS4ToInstanceId, params).then(function(data){
      modal.close();

      $scope.messages.status = 'success';
      $scope.messages.message = "環境の移行を開始します。";
      $scope.doneCommand = true;
    });
  };

  var _openInstanceList = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_instance.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope, $location, Instances){
        Instances.listInstances($scope.$parent.upgradeS4ToInstanceId).then(function(data){
          $scope.instances = data.content;
          if ( data.totalElements == 0 ) {
            $scope.hideTable = true;
          }

          // Pagination
          $scope.totalItems = data.totalElements;
          $scope.currentPage = data.number + 1
          $scope.maxSize = 7;
          $scope.itemsPerPage = data.size;

          $scope.pageChanged = function() {
            $location.path('/modal/instances/pages/' + $scope.currentPage);
          };
        });

        $scope.choose = function() {
          var selected = $scope.selectedInstance;
          var items = selected.split(';');
          $scope.$parent.upgradeS4ToHostName = item[0];
          $scope.$parent.upgradeS4ToIp = item[1];
          $uibModalInstance.close();
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
  };

  $scope.upgradeS4 = _upgradeS4;
  $scope.openInstanceList = _openInstanceList;
};

module.exports = upgradeS4Controller;
