'use strict';

News.$inject = [
  '$rootScope',
  '$http',
  'API'
];
function News( $rootScope, $http, API ) {
  var _listAvailableNews = function() {
    var url = API + '/api/news/?via=console';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getNews = function(id) {
    var url = API + '/api/news/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    listAvailableNews: function() {
      return _listAvailableNews().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    getNews: function(id) {
      return _getNews(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    }
  }
};

module.exports = News;
