'use strict';

newInstanceController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$location',
  'Orders',
  'Instances',
  'IaasAccounts',
  'Vendors'
];

function newInstanceController($scope, $localStorage, $uibModal, $location, Orders, Instances, IaasAccounts, Vendors) {

  //////////////////////////////////////////////////////////////////////
  // Initialize binding data
  var _initialize = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $scope.vendorId = null;
    $scope.iaasAccountId = null;

    $scope.order = null;
    $scope.customer = null;
    $scope.customerPerson = null;

    $scope.isProvision = true;
    $scope.instanceInitialMtAdminNickname = '';
    $scope.instanceInitialMtAdminEmail = '';
    $scope.domainNamePrefix = '';
    $scope.instanceUsage = $scope.usages[1].id;

    $scope.isFTPProvide = false;
    $scope.ftpAccount01 = '';
    $scope.ftpPassword01 = '';
    $scope.ftpAccount02 = '';
    $scope.ftpPassword02 = '';
    $scope.instanceOs = 'rocky';

    $scope.searchOrderNum = '';

    var user = $localStorage.user;
    Vendors.getVendorsByAccount(user.accountId).then(function(data){
      $scope.vendors = data;

      if (!angular.isUndefined($location.search()['orderId'])) {
        $scope.orderId = decodeURIComponent($location.search()['orderId']);
        _loadOrder($scope.orderId).then(function(){
          modal.close();
        });
      }
      else {
        modal.close();
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Find order by its ID
  var _loadOrder = function(orderId) {

    return Orders.getOrder(orderId).then(function(data){
      if (data.instances && data.instances.length > 0) {
        var canDeploy = data.instances.every(function(instance, i) {
          if (instance.instanceStatus == 3
              || instance.instanceStatus == 0
              || instance.instanceStatus == 8
              || instance.instanceStatus == 5
              || instance.instanceStatus == 11) {
            return true;
          }
        });
        if (!canDeploy) {
          $scope.messages.status = 'danger';
          $scope.messages.message = 'すでに稼働中のインスタンスが存在します。';
          return;
        }
      }

      $scope.order = data;
      if ( data.contactCustomerType == 0) {
        $scope.customer = data.deliveryCustomer;
        $scope.customerPerson = data.deliveryCustomerPerson;
      }
      else {
        $scope.customer = data.customer;
        $scope.customerPerson = data.customerPerson;
      }

      var vid = data.vendor.vendorId;
      $scope.vendorId = vid;

      if ( data.orderHostnameDesired ) {
        $scope.domainNamePrefix = data.orderHostnameDesired;
      }

      _loadIaasAccounts($scope.vendorId);
    },
    function(data){
      $scope.messages.status = 'danger';
      $scope.messages.message = data.data.message;
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Load IaasAccount list
  var _loadIaasAccounts = function(vendorId) {
    IaasAccounts.findIaasInstanceByVendor($scope.vendorId).then(function(data){
      if (data.message == undefined) {
        if (data.length) {
          var iaasAccounts = [{
            id: 0,
            label: '自動設定'
          }];
          angular.forEach(data, function(iaasAccount, i) {
            iaasAccounts.push({
              id: iaasAccount.iaasAccountId,
              label: iaasAccount.iaasAccountName + " - " + iaasAccount.iaasAccountZoneName
            });
          })
          $scope.iaasAccounts = iaasAccounts;
          $scope.iaasAccountId = $scope.iaasAccounts[0].id;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = 'デプロイ先が見つかりません。システム管理者にご連絡ください。';
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Reload and initialize related object of vendor
  var _changeVendor = function() {

    $scope.order = null;
    $scope.iaasAccountId = null;

    $scope.messages.status = '';
    $scope.messages.message = '';

    if (!$scope.vendorId) {
      return;
    }

    // Reload list of IaasAccounts
    _loadIaasAccounts($scope.vendorId);
  };

  //////////////////////////////////////////////////////////////////////
  // Open order select modal window
  var _listOrders = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_order.html",
      backdrop: "static",
      scope: $scope,
      controller : 'orderModalController'
    });

    // Result
    modal.result.then(
      function(data){
        $scope.order = data;
        $scope.searchOrderNum = '';
        if ( data.contactCustomerType == 0) {
          $scope.customer = data.deliveryCustomer;
          $scope.customerPerson = data.deliveryCustomerPerson;
          $scope.instanceInitialMtAdminNickname = data.deliveryCustomerPerson.customerPersonName;
          $scope.instanceInitialMtAdminEmail = data.deliveryCustomerPerson.customerPersonEmail;
        }
        else {
          $scope.customer = data.customer;
          $scope.customerPerson = data.customerPerson;
          $scope.instanceInitialMtAdminNickname = data.customerPerson.customerPersonName;
          $scope.instanceInitialMtAdminEmail = data.customerPerson.customerPersonEmail;
        }
        if ( data.orderHostnameDesired ) {
          $scope.domainNamePrefix = data.orderHostnameDesired;
        }
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open new order modal
  var _createNewOrder = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_order.html",
      backdrop: "static",
      scope: $scope,
      controller : 'newOrderModalController'
    });
    modal.result.then(
      function (data) {
        // Saved
        $scope.order = data;
        if ( data.contactCustomerType == 0) {
          $scope.customer = data.deliveryCustomer;
          $scope.customerPerson = data.deliveryCustomerPerson;
        }
        else {
          $scope.customer = data.customer;
          $scope.customerPerson = data.customerPerson;
        }
        $scope.messages.status = 'success';
        $scope.messages.message = "注文情報先を追加しました。";
      },
      function (result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new instance request
  var _deploy = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var params = {
      customerId: $scope.customer.customerId + '',
      orderId: $scope.order.orderId + '',
      nickname: $scope.instanceInitialMtAdminNickname,
      email: $scope.instanceInitialMtAdminEmail,
      os: $scope.instanceOs + '',
      prefix: $scope.domainNamePrefix,
      usage: $scope.instanceUsage + '',
      vendorId: $scope.vendorId + '',
      isProvision: $scope.isProvision + '',
      cloudTypeId: $scope.order.product.cloudType.cloudTypeId + ''
    };

    if ($scope.iaasAccountId != 0) {
      params.iaasAccountId = $scope.iaasAccountId + '';
    }

    if ($scope.isFTPProvide) {
      params.ftpAccount01 = $scope.ftpAccount01;
      params.ftpAccount02 = $scope.ftpAccount02;
    }

    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Instances.deploy(params).then(function(data){
      modal.close();

      if ( data.error || data.message ) {
        $scope.messages.status = 'danger';
        if ( data.message == "null" )
          $scope.messages.message = '不明なエラーが発生しました。';
        else
          $scope.messages.message = data.message;
      }
      else {
        $scope.messages.status = 'success';
        $scope.messages.message = "インスタンスの作成を開始しました。";

        $location.url('/instances/new');
        _initialize();
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Function Map
  $scope.changeVendor = _changeVendor;
  $scope.openNewOrder = _createNewOrder;
  $scope.deploy = _deploy;
  $scope.searchOrder = _listOrders;

  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.usages = [
    {id: 0, label: "無償提供"},
    {id: 1, label: "通常"},
    {id: 2, label: "トライアル"},
    {id: 4, label: "検証用"},
    {id: 99, label: "社内利用"},
  ];

  _initialize();

};

module.exports = newInstanceController;
