'use strict';

Vendors.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Vendors( $rootScope, $http, API ) {
  var _getBillingById = function(vendorId, month) {
    var url = API + '/api/vendors/' + vendorId + '/billing/' + month;
    return $http.get(url);
  };

  var _listVendors = function() {
    var url = API + '/api/vendors/';
    return $http.get(url);
  };

  var _getVendorsByAccount = function(userId) {
    var url = API + '/api/users/' + userId + '/vendors';
    return $http.get(url);
  };

  return {
    getBillingById: function(vendorId, month) {
      return _getBillingById(vendorId, month).then(function(res) {
        return res.data;
      });
    },
    listVendors: function() {
      return _listVendors().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getVendorsByAccount: function(userId) {
      return _getVendorsByAccount(userId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Vendors;
