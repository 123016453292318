'use strict';

orderModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Orders'
];

function orderModalController($uibModalInstance, $scope, $uibModal, Orders) {

  // Initialize
  $scope.selectedRow = null;

  // Load Order
  var modal = $uibModal.open({
    templateUrl: "views/modal/progress.html",
    backdrop: "static",
    keyboard: false
  });

  Orders.ListOrderByVendor($scope.$parent.vendorId, 1, $scope.$parent.searchOrderNum).then(function(result){
      var data = result.content;

    if (data && data.length) {
      var orders = [];

      angular.forEach(data, function(order, i){
        orders.push(order);
      });
      if ( orders.length > 0) {
        $scope.orders = orders;
        $scope.hideTable = false;
      }
      else {
        $scope.hideTable = true;
      }
    }
    else {
      $scope.hideTable = true;
    }
    modal.close();
  },
  function(data){
    $uibModalInstance.dismiss(data.message);
    modal.close();
  })

  // Select Row
  $scope.selectRow = function(index) {
    if ($scope.selectedRow == index) {
      $scope.order = null;
      $scope.selectedRow = null;
    } else {
      $scope.order = $scope.orders[index];
      $scope.selectedRow = index;
    }
  };

  // OK
  $scope.choose = function() {
    $uibModalInstance.close($scope.order);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

}

module.exports = orderModalController;
