'use strict';

feedbackController.$inject = [
  '$scope',
  '$routeParams',
  '$localStorage',
  '$uibModal',
  '$location',
  'Feedback'
];

function feedbackController($scope, $routeParams, $localStorage, $uibModal, $location, Feedback) {
  $scope.feedbackSubjectList = [
    {value: 'ご契約に関するお問い合わせ', label: 'ご契約に関するお問い合わせ'},
    {value: 'ご請求に関するお問い合わせ', label: 'ご請求に関するお問い合わせ'},
    {value: 'Movable Type クラウド版に関するお問い合わせ', label: 'Movable Type クラウド版に関するお問い合わせ'},
    {value: 'Cloud Control Center System に関するお問い合わせ', label: 'Cloud Control Center System に関するお問い合わせ'},
    {value: 'freetext', label: 'その他のお問い合わせ'},
  ];
  $scope.messages = {
    status: '',
    message: ''
  };
  $scope.feedbackSubjectFreeText = '';
  $scope.feedbackBody = '';

  var _sendFeedback = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    // Reset freetext input when user not selected freetext
    if ( $scope.feedbackSubject != 'freetext' )
      $scope.feedbackSubjectFreeText = '';

    var data = {};
    data.feedbackSubject = $scope.feedbackSubject;
    data.feedbackSubjectFreeText = $scope.feedbackSubjectFreeText;
    data.feedbackBody = $scope.feedbackBody;

    Feedback.sendFeedback(data).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'お問い合わせを受け付けました。';
        $scope.isSent = true;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
        $scope.isSent = false;
      }
    });
  };

  var _backToDashbioard = function() {
    $location.path('/dashboard');
  };

  $scope.sendFeedback = _sendFeedback;
  $scope.back = _backToDashbioard;
}

module.exports = feedbackController;
