'use strict';

Users.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Users ( $rootScope, $http, API ) {
  var _getUser = function(userId) {
    var url = API + '/api/users/' + userId;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(user_id, params) {
    var url = API + '/api/users/' +  user_id;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _changePassword = function(id, params) {
    var url = API + '/api/users/' + id + '/changePassword';
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _getVendors = function(id) {
    var url = API + '/api/users/' + id + '/vendors';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _passwordResetReuqest = function(email) {
    var url = API + '/forgot';
    var host = $location.protocol() + '://' + $location.host();
    if ( $location.port() != 80 && $location.port() != 433 )
      host += ':' + $location.port();
    var data = {
      email: email,
      host: host
    };

    return $http({
      method: 'post',
      url: url,
      data: data
    });

  };

  var _resetPassword = function(token, password) {
    var url = API + '/reset_password';
    var data = {
      token: token,
      password: password
    };

    return $http({
      method: 'post',
      url: url,
      data: data
    });
  };

  var _updateUser = function(userInfo) {
    var url = API + '/api/users/' + userInfo.accountId;
    var data = {
      displayName: userInfo.accountDisplayName,
      email: userInfo.accountEmail
    };
    if (userInfo.accountPassword != '') {
      data.password = userInfo.accountPassword
    }

    return $http({
      method: 'put',
      url: url,
      data: data
    });
  };

  return {
    getUser: function(userId) {
      return _getUser(userId);
    },
    save: function(user_id, params) {
      return _save(user_id, params);
    },
    changePassword: function(id, params) {
      return _changePassword(id, params);
    },
    getVendors: function(id) {
      return _getVendors(id);
    },
    passwordResetReuqest: function(email) {
      return _passwordResetReuqest( email ).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    resetPassword: function(token, password) {
      return _resetPassword( token, password ).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    updateUser: function(userInfo) {
      return _updateUser( token, password ).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = Users;
