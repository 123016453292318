'use strict';

planSelectorModalControll.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers',
  'Products',
  'params'
];

function planSelectorModalControll($uibModalInstance ,$scope, $uibModal, Customers, Products, params) {

  // Initialize
  var instance  = params.instance;
  var flex = params.flex;
  $scope.hideTable = true;

  $scope.versions = [
    { label: 'Movable Type 8', value: '8' },
    { label: 'Movable Type 7', value: '7' },
    { label: 'Movable Type 6', value: '6' },
  ];
  $scope.serverTypes = [
    { label: 'nginx', value: 'nginx' },
    { label: 'Apache HTTPD', value: 'httpd' }
  ];

  var _listProducts = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    Products.listProducts(instance.order.vendor.vendorId, $scope.mtVersion, $scope.webServer).then(
      function(data){
        if (data && data.length > 0) {
          var available = [];
          angular.forEach(data, function(p, i){
            if (p.productName != instance.instanceDetail.instanceDetailPlan ) {
              if ( !flex ) {
                var disk = parseInt(p.productMetaDataDisk, 10);
                if ( disk >= instance.instanceDetail.instanceDetailDisk
                    && p.productMetaDataWebserver === instance.instanceDetail.instanceDetailServerType
                    && p.productPriceType === 0 /* Monthly */) {
                      available.push(p);
                }
              }
              else {
                available.push(p);
              }
            }
          });

          modal.close();
          $scope.products = available;
        }
        if ( $scope.products.length > 0) {
          $scope.hideTable = false;
        }
        else {
          $scope.hideTable = true;
        }
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  _listProducts();

  // Filter procut
  $scope.filterProductList = _listProducts;

  // Select Row
  $scope.selectRow = function(index) {
    if ($scope.selectedRow == index) {
      $scope.product = null;
      $scope.selectedRow = null;
    } else {
      $scope.product = $scope.products[index];
      $scope.selectedRow = index;
    }
  };

  // OK
  $scope.choose = function() {
    $uibModalInstance.close($scope.product);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

module.exports = planSelectorModalControll;