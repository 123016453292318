'Use strict';

vendorBillingController.$inject = [
  '$scope',
  '$routeParams',
  '$localStorage',
  '$uibModal',
  '$q',
  'Vendors'
];

function vendorBillingController($scope, $routeParams, $localStorage, $uibModal, $q, Vendors) {
  $scope.messages = {
    status: '',
    message: ''
  };

  var _getBillingById = function( vendorId, month ) {
    var deferred = $q.defer();

    Vendors.getBillingById(vendorId, month).then(
      function(result){
        if (result.message == undefined) {
          deferred.resolve(result);
        } else {
          deferred.reject(result.message);
        }
      },
      function (result) {
        deferred.reject(result.data.message);
      }
    );
    return deferred.promise;
  }

  var modal = $uibModal.open({
    templateUrl: "views/modal/progress.html",
    backdrop: "static",
    keyboard: false
  });

  var vendorId = $routeParams.id;

  var now = new Date(); 
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var thisYearMonth = '' + year +  ( '00' + month ).slice( -2 );

  month++;
  if ( month == 13) {
    year++;
    month = 1;
  }
  var nextYearMonth = '' + year +  ( '00' + month ).slice( -2 );

  var requests = {
    thisMonth: _getBillingById( vendorId, thisYearMonth ),
    nextMonth: _getBillingById( vendorId, nextYearMonth )
  }
  $q.all(requests)
    .then(
      function(results){
        if ( results.thisMonth )
          $scope.thisMonth = results.thisMonth;
        if ( results.nextMonth )
          $scope.nextMonth = results.nextMonth;
      },
      function(result){
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    )
    .finally(
      function() {
        modal.close();
      }
    )
  ;
};

module.exports = vendorBillingController;
