'use strict';

IaasAccounts.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function IaasAccounts( $rootScope, $http, API ) {
  var _findIaasInstanceByVendor = function(vendorId) {
    var url = API + '/api/iaasAccounts/vendors/' + vendorId;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _countInstanceByVendor = function() {
    var url = API + '/api/iaasAccounts/count';
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    findIaasInstanceByVendor: function(vendorId) {
      return _findIaasInstanceByVendor(vendorId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    countInstanceByVendor: function() {
      return _countInstanceByVendor().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    }
  };
};

module.exports = IaasAccounts;
