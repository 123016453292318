'use strict';

Feedback.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Feedback( $rootScope, $http, API ) {
  var _sendFeedback = function(data) {
    var url = API + '/api/feedbacks/new';
    return $http({
      method: 'post',
      url: url,
      data: data
    });
  };

  return {
    sendFeedback: function( data ) {
      return _sendFeedback( data ).then(
        function(res) {
          return res.data;
        },
        function(res) {
          return res.data;
        }
      )
    }
  };
};

module.exports = Feedback;
