'use strict'

Orders.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Orders( $rootScope, $http, API ) {
  var _loadOrderList = function(customerId) {
    var url = API + '/api/orders/?customerId=' + customerId;
    return $http.get(url);
  };

  var _loadOrderListByVendor = function(vendorId, page, orderNum) {
    var url = API + '/api/orders/vendors/' + vendorId + '/pages/' + page + '?searchFreeWord=' + orderNum;
    return $http.get(url);
  };

  var _save = function(params) {
    var url = API + '/api/orders/';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _getOrder = function(orderId){
    var url = API + '/api/orders/' + orderId;
    return $http.get(url);
  };

  var _listOrdersByCustomer = function(customerId) {
    var url = API + '/api/orders/';
    return $http({
      method: 'get',
      url: url,
      params: {
        customerId: customerId
      }
    });
  };

  return {
    listOrderByCustomer: function( customerId ) {
      return _loadOrderList( customerId ).then(function(res) {
        return res.data;
      });
    },
    save: function(params) {
      return _save(params).then(function(res){
        return res.data;
      });
    },
    getOrder: function(orderId) {
      return _getOrder(orderId).then(function(res){
        return res.data;
      })
    },
    ListOrderByVendor: function(vendorId, page, orderNum) {
      return _loadOrderListByVendor(vendorId, page, orderNum).then(function(res) {
        return res.data;
      })
    },
    listOrdersByCustomer: function(customerId) {
      return _listOrdersByCustomer(customerId).then(function(res) {
        return res.data;
      })
    }
  };
};

module.exports = Orders;
