'use strict';

global.jQuery = require('jquery');

var angular = require('angular');

require('angular-animate');
require('angular-jwt');
require('angular-resource');
require('angular-route');
require('angular-sanitize');
require('angular-ui-bootstrap');
require('angular-chart.js');
require('chart.js');
require('ngstorage');
require('bootstrap');

var app = angular.module('app', ['ngRoute','ngResource', 'ngStorage', 'ui.bootstrap', 'ngSanitize', 'chart.js']);

if ( !process.env.NODE_ENV ) {
  process.env.NODE_ENV = "development";
}
if (process.env.NODE_ENV === "development") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return location.protocol + "//" + location.hostname + ':8080';
    }
  });
}
if (process.env.NODE_ENV === "stage") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return 'https://stage.api.movabletype.cloud';
    }
  });
}
if (process.env.NODE_ENV === "production") {
  app.provider( 'API', function($locationProvider){
    this.$get = function() {
      return "https://api.movabletype.cloud";
    }
  });
}

if (process.env.NODE_ENV !== "development") {
  app.factory('$exceptionHandler', function ($log) {
    var airbrake = new airbrakeJs.Client({
      projectId: 109229,
      projectKey: 'a5df6f96ffeba38bb92926035028031c'
    });

    airbrake.addFilter(function (notice) {
      notice.context.environment = process.env.NODE_ENV;
      return notice;
    });

    return function (exception, cause) {
      $log.error(exception);
      airbrake.notify({error: exception, params: {angular_cause: cause}});
    };
  });
}

require('./controllers');
require('./models');
require('./services');

app.config(['$routeProvider', '$httpProvider', '$locationProvider', function($routeProvider, $httpProvider, $locationProvider) {
  $locationProvider.hashPrefix('');

  $routeProvider
    .when('/signin',{
      templateUrl: '/views/signin.html',
      controller: 'authController'
    })
    .when('/dashboard',{
      templateUrl: '/views/dashboard.html',
      controller: 'dashboardController'
    })
    .when('/instances/new', {
      templateUrl: '/views/new_instance.html',
      controller: 'newInstanceController'
    })
    .when('/instances/pages/:num', {
      templateUrl: '/views/list_instance.html',
      controller: 'instanceListController'
    })
    .when('/instances/:id', {
      templateUrl: '/views/instance.html',
      controller: 'instanceController'
    })
    .when('/instances/:id/upgradeS4', {
      templateUrl: '/views/upgrade_s4.html',
      controller: 'upgradeS4Controller'
    })
    .when('/instances/:id/transfer', {
      templateUrl: '/views/transfer.html',
      controller: 'transferController'
    })
    .when('/instances/:id/sandbox', {
      templateUrl: '/views/sandbox.html',
      controller: 'sandboxController'
    })
    .when('/signout',{
      redirectTo: '/signin'
    })
    .when('/users/:id', {
      templateUrl: '/views/user.html',
      controller: 'userController'
    })
    .when('/vendors/:id/billing', {
      templateUrl: '/views/vendor_billing.html',
      controller: 'vendorBillingController'
    })
    .when('/feedback/new', {
      templateUrl: '/views/contact.html',
      controller: 'feedbackController'
    })
    .when('/customers/pages/:num', {
      templateUrl: '/views/list_customer.html',
      controller: 'customerListController'
    })
    .when('/customers/:id', {
      templateUrl: '/views/edit_customer.html',
      controller: 'customerController'
    })
    .when('/instances/:id/logs', {
      templateUrl: '/views/server_log.html',
      controller: 'serverLogController'
    })
    .otherwise(
      { redirectTo: '/signin'}
  );

  $httpProvider.interceptors.push([
    '$q',
    '$localStorage',
    '$location',
    '$injector',
    '$window',
    'API',
    function($q, $localStorage, $location, $injector, $window, API) {
      return {
        'request': function(config) {
          var url = config.url;
          var regex = new RegExp('^' + API + '/api/')
          if (url.match(regex)) {
            if ($localStorage.token) {
              config.headers['Authorization'] = "Bearer " + $localStorage.token;
            } else {
              $location.redirect('/signin');
            }
          }
          return config;
        },
        'responseError': function(response) {
          if (response.status===401) {
            var deferred = $q.defer();
            var url = API + '/login';
            var regex = new RegExp('^' + url);
            if (response.config.url.match(regex)) {
              deferred.reject(response);
            } else {
              var param = {
                username: $localStorage.username,
                password: $localStorage.password
              };
              $injector.get('$http')({
                method: 'post',
                url: url,
                data: param
              }).then( function onSuccess(res){
                var token = res.data.token;
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var parsed = JSON.parse($window.atob(base64));

                $localStorage.token = token;
                $localStorage.tokenParsed = parsed;
                $localStorage.isAuthed = true;

                $injector.get("$http")(response.config).then(function(resp) {
                  deferred.resolve(resp);
                }, function(resp) {
                  deferred.reject();
                });
              }, function onError(data, status, headers, config){
                deferred.reject();
                $location.path('/signin');
                return;
              });
            }
            return deferred.promise;
          }
          return $q.reject(response);
        }
      };
    }
  ]);
}]);

app.run([
  '$rootScope',
  '$location',
  '$route',
  '$localStorage',
  function($rootScope, $location, $route, $localStorage){
    $rootScope.$on('$routeChangeStart', function(ev, next, current){
      if (next.controller == 'authController') {
        if (typeof current !== "undefined" && current.originalPath != '/signout') {
          if ($localStorage.token) {
            $location.path('/dashboard');
            $route.reload();
          }
        }
      } else {
        if (!$localStorage.token) {
          $location.path('/signin');
          $route.reload();
        }
      }
    });
  }
]);
