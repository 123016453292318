'use strict';

customerController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  'Customers',
  'Orders',
  'CustomerPersons'
];
function customerController($scope, $uibModal, $routeParams, $q, Customers, Orders, CustomerPersons) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _edit = function() {
    $scope.editMode = true;
  };

  var _save = function() {
    var data = {
      name: $scope.customer.customerName,
      tel: $scope.customer.customerTel,
      email: $scope.customer.customerEmail,
      address: $scope.customer.customerAddress,
      zipCode: $scope.customer.customerZipCode
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Customers.save($scope.customer.customerId, data).then(function(result){
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = '顧客情報を保存しました。';
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    })
  }

  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "顧客の削除";
        $scope.messageBody = "この顧客を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Customers.delete($scope.customer.customerId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "顧客を削除しました。";
          $scope.customer = data;
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  var _editPerson = function($index) {
    var customerPerson = $scope.customerPersons[$index];
    var res = _openCustomerPersonModal(customerPerson).then(
      function(res){
        $scope.customerPerson[$index] = res;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
        return;
      }
    );
  };

  var _AddPerson = function() {
    _openCustomerPersonModal().then(
      function(res){
        $scope.customerPersons.push(res);
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
        return;
      }
    );
  };

  var _deletePerson = function($index) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var customerPerson = $scope.customerPersons[$index];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "顧客担当者の削除";
        $scope.messageBody = "顧客担当者（" + customerPerson.customerPersonName + "）を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      CustomerPersons.delete(customerPerson.customerPersonId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "顧客担当者を削除しました。";
          $scope.customerPersons.splice($index, 1);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.edit = _edit;
  $scope.save = _save;
  $scope.delete = _delete;
  $scope.editPerson = _editPerson;
  $scope.deletePerson = _deletePerson;
  $scope.addPerson = _AddPerson;

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _resetAction = function(data) {
    $scope.action = null;
    if ( data.customerStatus == 1) {
      // Enabled
      $scope.action = {};
      $scope.action.canEdit = true;
      if ( $scope.orders ) {
        var canDelete = true;
        $scope.orders.some(function(order){
          if ( order.orderStatus == 1 || order.orderStatus == 3 || order.orderStatus == 4) {
            canDelete = false;
            return true;
          }
        })
        $scope.action.canDelete = canDelete;
      }
      else {
        $scope.action.canDelete = true;
      }
    }
    else if ( data.orderStatus == 2 ) {
      // Disabled
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canDelete = true;
    }
    else if ( data.orderStatus == 0) {
      // Deleted
      $scope.action = {};
      $scope.action.canEdit = false;
      $scope.action.canDelete = false;
    }
  };

  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadCustomer(),
      _loadOrders(),
      _loadCustomerPersons()
    ])
    .then(function(){
      _resetAction($scope.customer);
      modal.close();
    });
  };

  var _loadCustomer = function() {
    return Customers.load($routeParams.id).then(function(data){
      if (data.message == undefined) {
        $scope.customer = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  var _loadOrders = function() {
    return Orders.listOrdersByCustomer($routeParams.id).then(function(data){
      if (data.message == undefined) {
        if ( data.length > 0)
          $scope.orders = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  var _loadCustomerPersons = function() {
    return CustomerPersons.listCustomerPersonList($routeParams.id).then(function(data){
      if (data.message == undefined) {
        if ( data.length > 0)
          $scope.customerPersons = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  var _openCustomerPersonModal = function(customerPerson) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/edit_customer_person.html",
      backdrop: "static",
      controller : 'editCustomerPersonController',
      resolve: {
        params: function() {
          return {
            customer: $scope.customer,
            customerPerson: customerPerson,
            isNew: customerPerson ? false : true
          };
        }
      }
    });

    return modal.result;
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.editMode = false;
  $scope.editPersonMode = false;
  $scope.loadingOrder = true;
  $scope.customer = null;
  $scope.orders = null;
  $scope.customerPersons = null;

  // Load data
  _load();

};

module.exports = customerController;
