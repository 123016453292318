'use strict';

newCustomerPersonController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'CustomerPersons'
];

function newCustomerPersonController($uibModalInstance, $scope, $uibModal, CustomerPersons) {

  // Initialize
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.customerName = $scope.$parent.customer.customerName;
  $scope.customerId = $scope.$parent.customer.customerId;

  $scope.save = function() {
    var customerId = $scope.customerId;
    var params = {
      customerId: $scope.customerId,
      customerPersonName: $scope.customerPersonName,
      customerPersonEmail: $scope.customerPersonEmail,
      customerPersonDepartment: $scope.customerPersonDepartment,
      customerPersonTel: $scope.customerPersonTel
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    CustomerPersons.save(params).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $uibModalInstance.close(data);
      } else {
        $uibModalInstance.dismiss(data.message);
      }
    })
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = newCustomerPersonController;
