'use strict';

authController.$inject = [
  '$scope',
  '$location',
  '$window',
  '$localStorage',
  'AuthService',
  'Users'
];

function authController($scope, $location, $window, $localStorage, AuthService, Users) {
  $scope.message = '';
  $localStorage.$reset();

  var _signIn = function(){
    $scope.message = '';

    AuthService.signIn($scope.username, $scope.password)
      .then( function (res) {
        var token = res.data.token;
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var parsed = JSON.parse($window.atob(base64));

        $localStorage.$default({
          username: $scope.username,
          password: $scope.password,
          token: token,
          tokenParsed: parsed,
          isAuthed: true
        });

        return Users.getUser('me');
      })
      .then ( function(res) {
        $localStorage.$default({
          user: res.data
        });
        return Users.getVendors(res.data.accountId)
      })
      .then( function(res) {
        if ( res.data.length == 0) {
          $q.reject();
        }
        $localStorage.$default({
          vendors: res.data
        });
        $location.path('/dashboard');
      })
      .catch( function(res) {
        $localStorage.$reset();
        $scope.message = "認証に失敗しました。";
      });
  };

  $scope.signIn = _signIn;
}

module.exports = authController;
