'use strict';

var angular = require('angular');

angular.module('app').factory('Feedback', require('./Feedback'));
angular.module('app').factory('IaasAccounts', require('./IaasAccounts'));
angular.module('app').factory('Instances', require('./Instances'));
angular.module('app').factory('Jobs', require('./Jobs'));
angular.module('app').factory('News', require('./News'));
angular.module('app').factory('Orders', require('./Orders'));
angular.module('app').factory('Products', require('./Products'));
angular.module('app').factory('Vendors', require('./Vendors'));
angular.module('app').factory('Customers', require('./Customers'));
angular.module('app').factory('CustomerPersons', require('./CustomerPersons'));
angular.module('app').factory('Users', require('./Users'));
