'use strict';

newCustomerModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers'
];

function newCustomerModalController($uibModalInstance, $scope, $uibModal, Customers) {

  $scope.customerName = "";
  $scope.customerPronetNo = "";
  $scope.customerTel = "";
  $scope.customerZipCode = "";
  $scope.customerAddress = "";

  $scope.save = function() {
    var params = {
      vendorId: $scope.$parent.vendorId,
      name: $scope.customerName,
      pronetNo: $scope.customerPronetNo,
      tel: $scope.customerTel,
      zipCode: $scope.customerZipCode,
      address: $scope.customerAddress
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Customers.save(null, params).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $uibModalInstance.close(data);
      } else {
        $uibModalInstance.dismiss(data.message);
      }
    })
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

}

module.exports = newCustomerModalController;
