'use strict';

Instances.$inject = [
  '$rootScope',
  '$http',
  'API'
]

function Instances( $rootScope, $http, API ) {
  var _deploy = function(params) {
    var url = API + '/api/instances/';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _listInstances = function( pageNum, params ) {
    var url = API + '/api/instances/pages/' + pageNum;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _listAuthors = function(id) {
    var url = API + '/api/instances/' + id + '/mt/authors';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listSites = function(id) {
    var url = API + '/api/instances/' + id + '/mt/sites';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstance = function(id) {
    var url = API + '/api/instances/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _stopInstance = function(id) {
    var url = API + '/api/instances/' + id + '/stop';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _startInstance = function(id) {
    var url = API + '/api/instances/' + id + '/start';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _transfer = function(id, params) {
    var url = API + '/api/instances/' + id + '/transfer';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _upgradeS4 = function(id, params) {
    var url = API + '/api/instances/' + id + '/upgradeS4';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _migration = function(id, params) {
    var url = API + '/api/instances/' + id + '/migration';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _getMigrationStatus = function(id) {
    var params = { command: "status" };
    var url = API + '/api/instances/' + id + '/migration';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _restoreStatus = function(id) {
    var url = API + '/api/instances/' + id + '/status/restore';
    return $http({
      method: 'post',
      url: url,
    });
  };

  var _upgradeMT8 = function(id) {
    var url = API + '/api/instances/' + id + '/upgradeMT';
    return $http({
      method: 'post',
      url: url,
    });
  };

  var _rollback = function(id, params) {
    var url = API + '/api/instances/' + id + '/rollback';
    return $http({
      method: 'post',
      url: url,
      data: params,
    });
  };

  var _getBackupFiles = function(id) {
    var url = API + '/api/instances/' + id + '/backup';
    return $http({
      method: 'post',
      url: url,
    });
  };

  var _destroy = function(id, flag, notifyAtDelete) {
    console.log(notifyAtDelete);
    var url = API + '/api/instances/' + id;
    if ( flag )
      url += '?immediately=1';
    if ( notifyAtDelete ) {
      if ( flag )
        url += '&notifyAtDelete=1';
      else
        url += '?notifyAtDelete=1';
    }
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _sandbox = function(id, params) {
    var url = API + '/api/instances/' + id + '/sandbox';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _rebootInstance = function(id, params) {
    var url = API + '/api/instances/' + id + '/reboot';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _changePlan = function(id, params) {
    var url = API + '/api/instances/' + id + '/changePlan';
    return $http({
      method: 'post',
      url: url,
      data: params
    })
  };

  var _exchange = function(id, params) {
    var url = API + '/api/instances/' + id + '/exchange';
    return $http({
      method: 'post',
      url: url,
      data: params
    })
  };

  var _changeExpireDays = function(id, params) {
    var url = API + '/api/instances/' + id + '/changeUpdatePeriod';
    return $http({
      method: 'post',
      url: url,
      data: params
    })
  };

  var _cancelDestroy = function(id) {
    var url = API + '/api/instances/' + id + '/cancelDelete';
    return $http({
      method: 'post',
      url: url
    })
  };

  var _domainExchange = function(id, params) {
    var url = API + '/api/instances/' + id + '/domainExchange';
    return $http({
      method: 'post',
      url: url,
      data: params
    })
  };

  var _getJobs = function(id) {
    var url = API + '/api/instances/' + id + '/jobs';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstanceDiskUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/disk';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstanceCpuUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/cpu';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getInstanceMemoryUsage = function(id) {
    var url = API + '/api/metrics/instances/' + id + '/memory';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getServerLog = function(id, type) {
    var url = API + '/api/instances/' + id + '/log';
    return $http({
      method: 'get',
      url: url,
      params: type
    });
  }

  var _getNetworkBandWidth = function(id, param) {
    var url = API + '/api/instances/' + id + '/network';
    return $http({
      method: 'get',
      url: url,
      params: param
    });
  };

  var _installClamAV = function( id ) {
    var url = API + '/api/instances/' + id + '/installClamAV';
    return $http( {
      method: 'post',
      url: url
    });
  }

  var _installShadankun = function( id, params ) {
    var url = `${API}/api/instances/${id}/installShadankun`;
    return $http( {
      method: 'post',
      url: url,
      data: params
    });
  }

  var _uninstallShadankun = function( id ) {
    var url = `${API}/api/instances/${id}/uninstallShadankun`;
    return $http( {
      method: 'post',
      url: url,
    });
  }

  var _controlShadankun = function( id, mode ) {
    var url = API + '/api/instances/' + id + '/shadankun/control';
    var param = {
      command: mode
    };
    return $http( {
      method: 'post',
      url: url,
      data: param,
    });
  }

  return {
    deploy: function(params) {
      return _deploy(params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listInstances: function( pageNum, searchParam ) {
      return _listInstances(pageNum, searchParam).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listAuthors: function(id) {
      return _listAuthors(id).then(
        function(res){
          return res.data;
        },
        function(res){
          if (res) {
            return res.data;
          } else {
            return null;
          }
        }
      );
    },
    listSites: function(id) {
      return _listSites(id).then(
        function(res){
          return res.data;
        },
        function(res){
          if (res) {
            return res.data;
          } else {
            return null;
          }
        }
      );
    },
    getInstance: function(id) {
      return _getInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    stopInstance: function(id) {
      return _stopInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    startInstance: function(id) {
      return _startInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    rebootInstance: function(id) {
      return _rebootInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    transfer: function(id, params) {
      return _transfer(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    upgradeS4: function(id, params) {
      return _upgradeS4(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    migration: function(id, cmd ) {
      var params = { command: cmd };
      return _migration(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getMigrationStatus: function(id) {
      var params = { command: "status" };
      return _migration(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    restoreStatus: function(id) {
      return _restoreStatus(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    rollback: function(id, date) {
      console.log( params );
      var params = { date: date };
      return _rollback(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    upgradeMT8: function(id) {
      return _upgradeMT8(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getBackupFiles: function(id) {
      return _getBackupFiles(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    destroy: function(id, flag, notifyAtDelete) {
      return _destroy(id, flag, notifyAtDelete).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    sandbox: function(id, params) {
      return _sandbox(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    changePlan: function(id, params) {
      return _changePlan(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    exchange: function(id, params) {
      return _exchange(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    domainExchange: function(id, params) {
      return _domainExchange(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    changeExpireDays: function(id, params) {
      return _changeExpireDays(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    cancelDestroy: function(id) {
      return _cancelDestroy(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    getJobs: function(id) {
      return _getJobs(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceDiskUsage: function(id) {
      return _getInstanceDiskUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceCpuUsage: function(id) {
      return _getInstanceCpuUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstanceMemoryUsage: function(id) {
      return _getInstanceMemoryUsage(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getServerLog: function(id, type) {
      return _getServerLog(id, type).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getNetworkBandWidth: function(id, param) {
      return _getNetworkBandWidth(id, param).then(
        function(res){
          return res.data;
        },
        function(res){
          if (res) {
            return res.data;
          } else {
            return 0;
          }
        }
      );
    },
    installClamAV: function(id) {
      return _installClamAV(id).then(
        function(res) {
          return res.data;
        },
        function(res) {
          if (res) {
            return res.data;
          } else {
            return 0;
          }
        }
      );
    },
    installShadankun: function(id, param) {
      return _installShadankun( id, param ).then(
        function(res) {
          return res.data;
        },
        function(res) {
          return 0;
        }
      );
    },
    uninstallShadankun: function(id) {
      return _uninstallShadankun( id ).then(
        function(res) {
          return res.data;
        },
        function(res) {
          return 0;
        }
      );
    },
    controlShadankun: function(id, mode) {
      return _controlShadankun( id, mode ).then(
        function(res) {
          return res.data;
        },
        function(res) {
          return res.data;
        }
      );
    },
    getShadankunStatus: function(id) {
      return _controlShadankun(id, "status").then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
  };
};

module.exports = Instances;
